<template>
  <FormComponent class="register" @submit="login">
    <h3 class="auth-title">
      <!-- <button class="btn" type="button" @click="back()">
        <span class="material-icons-outlined">arrow_back</span>
      </button> -->
      {{ $t('generic-str.login') }}
    </h3>
    <p style="color: lightslategray">
      {{ $t('auth.slogan') }}
    </p>
    <InputComponent name="email" type="email" :label="$tc('app.email', 1)" :placeholder="$t('app.type-email')" required
      v-model="form.email"></InputComponent>
    <PasswordInputComponent :label="$tc('app.password', 1)" :placeholder="$t('app.type-password')" required
      v-model="form.password" :validate="false"></PasswordInputComponent>
    <div class="checkbox">
      <router-link to="/reset-password" class="yup-purple-color forgot-password">{{
        $t('login-invite.forgot-password')
      }}</router-link>
    </div>
    <ButtonComponent type="submit" is-block :loading="isSending">
      {{ $t('generic-str.login') }}
    </ButtonComponent>
    <div class="row justify-content-center">
          <p class="register-cta">{{ $t('auth.dont-have-account') }}</p> <router-link to="/register" class="register-cta-a">{{ $t('app.register-alt') }}</router-link>
    </div>
    <div class="mb-4" v-if="isMainWhitelabel">
      <SeparatorComponent text="Ou" />
      <div class="d-flex align-items-center justify-content-center social-group">
        <GoogleButtonComponent @login="handleGoogleLogin"></GoogleButtonComponent>
        <FacebookButtonComponent @login="handleFacebookLogin"></FacebookButtonComponent>
      </div>
    </div>
    <ResendVerificationEmailModal ref="resendVerificationEmailModal" id="resend-verification-email" :email="form.email" @submit="fetch(1)" />
  </FormComponent>
</template>

<script>
// @ is an alias to /src
import { load } from 'recaptcha-v3';
import ResendVerificationEmailModal from '@/components/rebranding/ResendVerificationEmailModal.vue';
import User from '@/models/user';
import DarkMode from '@/assets/mixins/detectDark';
import FormComponent from '../../components/form/FormComponent.vue';
import InputComponent from '../../components/form/InputComponent.vue';
import PasswordInputComponent from '../../components/form/PasswordInputComponent.vue';
import ButtonComponent from '../../components/ui/ButtonComponent.vue';
import SeparatorComponent from '../../components/ui/SeparatorComponent.vue';
import GoogleButtonComponent from '../../components/ui/GoogleButtonComponent.vue';
import FacebookButtonComponent from '../../components/ui/FacebookButtonComponent.vue';

export default {
  name: 'Login',
  components: {
    FormComponent,
    InputComponent,
    PasswordInputComponent,
    ButtonComponent,
    SeparatorComponent,
    GoogleButtonComponent,
    FacebookButtonComponent,
    ResendVerificationEmailModal,
  },
  mixins: [DarkMode],
  data() {
    return {
      form: {
        email: '',
        password: '',
        recaptcha_token: '',
        device: '',
      },
      user: new User('', ''),
      isSending: false,
      isSendingGoogle: false,
      isSendingFacebook: false,
      show: false,
    };
  },
  computed: {
    isMainWhitelabel() {
      return (
        this.$store.state?.admin?.whitelabel?.['is_main'] ||
        !this.$store.state?.whitelabel
      );
    },
  },
  mounted() {
    this.form.device = localStorage.getItem('mobile');
  },
  methods: {
    back() {
      if (this.$router.currentRoute.path === '/login') {
        this.$parent.routing = false;
      } else {
        this.$router.back();
      }
    },
    recaptcha(callback) {
      if (process.env.NODE_ENV === 'production') {
        load(process.env.VUE_APP_RECAPTCHA_KEY, {
          useRecaptchaNet: true,
          autoHideBadge: true,
        }).then((recaptcha) => {
          recaptcha.execute('login').then((token) => {
            this.form.recaptcha_token = token;
            callback();
          });
        });
      } else {
        this.form.account_name = 'localhost';
        callback();
      }
    },
    login() {
      if (this.isSending) return;

      this.isSending = true;

      this.recaptcha(() => {
        this.$store.dispatch('auth/login', this.form).then(
          () => {
            this.isSending = false;
            this.$live.firstLaunch = true;
            // this.$router.push('/2fa');
            this.$router.push('/');
          },
          (error) => {
            if (error.code === 403) {
              this.$refs.resendVerificationEmailModal.show();
            }
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      });
    },
    handleGoogleLogin(response) {
      this.$store.dispatch('auth/loginWithGoogle', response).then(() => {
        this.$live.firstLaunch = true;
        this.$router.push('/');
      });
    },
    handleFacebookLogin(response) {
      this.$store.dispatch('auth/loginWithFacebook', response).then(() => {
        this.$live.firstLaunch = true;
        this.$router.push('/');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.social-group {
  gap: 16px;
}

.auth-title {
  font-size: 36px;
  margin-bottom: 10px;

  .btn {
    padding: 0;
    font-size: 24px;
  }
}

.checkbox {
  margin-bottom: 26px;
  font-weight: 500;
}

.forgot-password {
  font-size: 13px;
  font-weight: normal;
}

.register-cta {
  color: var(--clr-yup-purple);
  font-size: 14px;
  margin-left: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.register-cta-a {
  color: var(--clr-yup-purple);
  text-decoration: underline;
  font-size: 14px;
  margin-left: 3px;
  font-weight: 600;
}

::v-deep .form-group {
  label {
    color: #752DE6 !important;
    margin-bottom: 0.32rem!important;
    font-size: .785rem !important;
  }
}

::v-deep .form-group {
  label {
    span {
      display: none;
    }
  }
}

::v-deep .form-group {
  .form-control {
    border: 2px solid rgba(117, 45, 230, 0.16);
    padding: 10px 15px !important;
  }

  input[type="tel"] {
    border-right: 2px solid rgba(117, 45, 230, 0.16)!important;
    border-radius: 0px 10px 10px 0px!important;
  }

  .material-icons-outlined {
    color: gainsboro!important;
  }

  .v-select {
    .vs__dropdown-toggle {
      border-color: rgba(117, 45, 230, 0.16);
    }
  }
}

::v-deep .primary {
  height: 53px !important;
  font-weight: 600 !important;
}

::v-deep .btn-icon {
  border: 1px 1px 1px 0px;
  border-color: rgba(117, 45, 230, 0.16) !important;
}

::v-deep .dropdown-toggle {
  border: 1px 1px 1px 0px;
  border-color: rgba(117, 45, 230, 0.16) !important;
}

::v-deep .form-group {
  .v-select {
    .vs__dropdown-toggle {
      input {
        font-size: 14.3px!important;
        color: #C6CDDE!important;
      }
    }
  }
}

::v-deep .separator::before,
::v-deep .separator::after {
  border-top: 2px solid rgba(117, 45, 230, 0.16) !important;
}

::v-deep .separator {
  span {
        margin: 0px 10px;
        font-size: 15px;
  }
}

::v-deep .google-btn {
  width: 202px;
  border: 2px solid rgba(117, 45, 230, 0.16) !important;
  margin-bottom: 0px!important;
  &:hover {
    background-color: rgba(117, 45, 230, 0.16) !important;
  }
}

::v-deep .facebook-btn {
  width: 202px;
  border: 2px solid rgba(117, 45, 230, 0.16) !important;
  margin-bottom: 0px!important;
  &:hover {
    background-color: rgba(117, 45, 230, 0.16) !important;
  }
  background-color: white!important;
  i {
    color: #1877F2!important;
  }
}

h3, p {
  text-align: center;
}
</style>
